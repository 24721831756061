@import './app.scss';
@import './components/_error-pages.scss';


.container {
  width: 100%;
  height: 100%;
}

.error-container {
  text-align: center;
  margin: 0 auto;
  height: 50%;
  width: 50%;
  position: relative;
  top: 25%;

  h1 {
    text-align: center;
    width: 100%;
  }
}
